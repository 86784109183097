<template>
  <div class="slot-box" :class="classes">
    <slot>min, Width: {{ width }}</slot>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  box: HTMLElement | null,
  minWidth: number,
  maxWidth: number,
  classes?: string
}>();

// single ref
let width = ref(0);

const resizeObserver = new ResizeObserver(() => {
  if (props.box) {
    width.value = props.box.offsetWidth;
  }
});

onMounted(() => {
  nextTick().then(() => {
    try {
      if (props.box) {
        resizeObserver.observe(props.box);
      }
    } catch {
      watch(() => props.box, (newBox) => {
        if (newBox) {
          width.value = newBox.offsetWidth;
          resizeObserver.observe(newBox);
        }
      });
    }
  });
});

const display = computed(() => {
  if (width.value > props.minWidth && width.value < props.maxWidth) {
    return 'block';
  }
  return 'none';
});

onUnmounted(() => {
  resizeObserver.disconnect();
});
</script>

<style scoped lang="scss">
.slot-box {
  display: v-bind(display);
}
</style>
